
import { ChevronLeftIcon, ChevronRightIcon } from '~/components/veke-ui/icons'
export default {
  name: 'Navigation',
  components: { ChevronLeftIcon, ChevronRightIcon },
  inject: ['carousel'],
  props: {
    overlayArrows: {
      type: Boolean,
      default: false
    },
    /**
     * Amount of padding to apply around the label in pixels
     */
    clickTargetSize: {
      type: Number,
      default: 8
    },
    /**
     * Text content of the navigation next button
     */
    nextLabel: {
      type: String,
      default: '&#9654'
    },
    /**
     * Text content of the navigation prev button
     */
    prevLabel: {
      type: String,
      default: '&#9664'
    }
  },
  computed: {
    /**
     * @return {Boolean} Can the slider move forward?
     */
    canAdvanceForward () {
      return this.carousel.canAdvanceForward || false
    },
    /**
     * @return {Boolean} Can the slider move backward?
     */
    canAdvanceBackward () {
      return this.carousel.canAdvanceBackward || false
    }
  },
  methods: {
    /**
     * Trigger page change on +/- 1 depending on the direction
     * @param {"backward"} [direction]
     * @return {void}
     */
    triggerPageAdvance (direction) {
      /**
       * @event paginationclick
       * @type {string}
       */
      this.$emit('navigationclick', direction)
    }
  }
}
