
import Vue from 'vue'
import ProductTile from '~/components/category/ProductTile.vue'
import VekeCarousel from '~/components/veke-carousel/VekeCarousel.vue'
import type { ProductTileAlgolia } from '~/types/product'
import { SwatchActions } from '~/store/swatch'
import { attributesForProductTile } from '~/helpers/instantsearch'

export default Vue.extend({
  name: 'ProductTileCarousel',
  components: { VekeCarousel, ProductTile },
  props: {
    perPageCustom: {
      type: Array,
      required: false,
      default: () => [[320, 2], [768, 3], [1024, 4]]
    },
    slides: {
      type: Number,
      default: 12
    },
    categoryIds: {
      type: Array,
      required: false,
      default: null
    },
    parentSkus: {
      type: Array,
      required: false,
      default: null
    },
    skus: {
      type: Array,
      required: false,
      default: null
    },
    mainVariationsOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    productSearchQuery: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      products: [] as ProductTileAlgolia[]
    }
  },
  mounted () {
    this.$store.dispatch(SwatchActions.fetchSwatchesByType, 'Väri')
  },
  created () {
    let filters = null
    if (this.productSearchQuery) {
      filters = this.productSearchQuery
    } else {
      let orFilters: string[] = []
      const andFilters: string[] = []
      if (this.parentSkus) {
        orFilters = orFilters.concat(this.parentSkus.map(sku => `parentSku:'${sku}'`))
      }
      if (this.skus) {
        orFilters = orFilters.concat(this.skus.map(sku => `sku:'${sku}'`))
      }
      if (this.categoryIds) {
        orFilters = orFilters.concat(this.categoryIds.map(id => `categories.id:${id}`))
      }
      if (this.mainVariationsOnly) {
        andFilters.push('isMainVariation:true')
      }

      filters = [orFilters.join(' OR '), andFilters.join(' AND ')].filter(Boolean).map(fs => `(${fs})`).join(' AND ')
    }
    if (filters !== '') {
      this.$algolia().search('', { filters, attributesToHighlight: [], attributesToRetrieve: attributesForProductTile, hitsPerPage: this.slides }).then(({ hits }: { hits: ProductTileAlgolia[] }) => {
        this.products = hits
      })
    }
  },
  methods: {
    handleEvent (index: number) {
      this.$matomo.trackEvent(this.$parent?.$options?.name || 'UnknownSource', 'product-tile-carousel-click', 'carousel-index', index)
    }
  }
})
