
import { defineComponent, computed } from '@nuxtjs/composition-api'
import BaseButton from '~/components/BaseButton.vue'
import { ArrowRightIcon } from '~/components/veke-ui/icons'
import { useTime } from '~/composable/useTime'

export default defineComponent({
  name: 'BlogHighlightTile',
  components: { BaseButton, ArrowRightIcon },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const excerpt = computed(() => props.post.excerpt.rendered.replace('<p>', '').replace('</p>', ''))
    const parseDate = (date: string) => {
      const { formatISOString } = useTime()
      return formatISOString(date, 'd. MMMM yyyy')
    }

    return {
      parseDate,
      excerpt
    }
  }
})
