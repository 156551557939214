
import { defineComponent, useStore, computed, ComputedRef } from '@nuxtjs/composition-api'
import { CategoryGetters } from '~/store/category'
import type { CategoryItem } from '~/types/category'

export default defineComponent({
  name: 'PopularCategories',
  setup () {
    const store = useStore()

    const sortList = ['Viikon supertarjoukset', 'Outlet', 'Aina edulliset ', 'Puutarhakalusteet', 'Sohvat', 'Sängyt', 'Matot', 'Pöydät', 'Säilytys']

    // Take first 8 popular categories
    const popularCategories: ComputedRef<CategoryItem[]> = computed(() => store.getters[CategoryGetters.getPopularCategories].sort((a: CategoryItem, b: CategoryItem) => sortList.indexOf(a.name) > sortList.indexOf(b.name) ? 1 : -1).slice(0, 8))

    const getHyphenedName = (name: string) => name.replace('kalusteet', '&shy;kalusteet')

    const items = computed(() => popularCategories.value.slice().map((cat: CategoryItem) => ({ label: getHyphenedName(cat.value || ''), image: cat.product, path: `/${cat.path}` })))

    return { items }
  }
})
