
import { defineComponent } from '@nuxtjs/composition-api'
// @ts-ignore
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import useRichText from '~/mixins/useRichText'
import { PageMessageCssClasses, PageMessageItem } from '~/types/contentful/page-message'

export default defineComponent({
  name: 'PageMessage',
  components: {
    CentralizeAndSave: () => import('./widgets/CentralizeAndSave.vue'),
    RichTextRenderer
  },
  mixins: [useRichText],
  props: {
    message: {
      type: Object as () => PageMessageItem,
      required: true
    }
  },
  data () {
    return {
      isModalOpen: false
    }
  },
  computed: {
    classes (): PageMessageCssClasses {
      return this.message.cssClasses || ({
        wrapper: '',
        message: '',
        readMoreDivider: '',
        readMoreWrapper: '',
        readMoreButton: ''
      })
    }
  }
})
