
import YotpoReviewFrontpage from './YotpoReviewFrontpage.vue'
export default {
  name: 'YotpoReviewsFrontpage',
  components: {
    YotpoReviewFrontpage
  },
  data () {
    return {
      reviews: [
        {
          name: 'Linnea K.',
          text: '"Sohva on kaikkea sitä, mitä sohvalta voi toivoa: laadukas, kaunis, mukava, sopivan kokoinen ja nojatyynyt poistaessa sopii myös nukkumiseen. Paras sijoitus, mitä olen koskaan tehnyt!"',
          imageUrl: require('@/assets/images/asiakaskokemuksia/scandic-copenhagen-konjakki-kentucky-sohva-miljoossa-web-32.jpg'),
          productUrl: '/product/scandic-copenhagen-3-istuttava-sohva-konjakinruskea-kentucky-9-mustat-jalat',
          productName: 'Scandic Copenhagen 3-istuttava sohva'
        },
        {
          name: 'Elriikka P.',
          text: '"Olimme todella tyytyväisiä tuotteeseen, mahtuu hyvin pieneenkin lastenhuoneeseen ja silti jää tilaa leikkiä. Ei nitise eikä natise ja on todella tukeva!"',
          imageUrl: require('@/assets/images/asiakaskokemuksia/liisa_pieni_kerrossanky_musta-20.jpg'),
          productUrl: '/product/liisa-pieni-kerrossanky-70x155cm-musta',
          productName: 'Liisa pieni kerrossänky 70x155cm'
        },
        {
          name: 'Tuuli N.',
          text: '"Ihanan pehmeä ja lämpöinen matto jalan alla. Pysyy hyvin paikoillaan ja kaunis väri vastasi kuvassa ollutta."',
          imageUrl: require('@/assets/images/asiakaskokemuksia/usva_nukkamatto-web-4.jpg'),
          productUrl: '/product/usva-nukkamatto-80x150-cm-beige',
          productName: 'Usva nukkamatto'
        },
        {
          name: 'Sini A.',
          text: 'Senkki on juuri niin kaunis, laadukas ja viimeistelty kuin toivoinkin. Toimitus oli nopeaa ja sujuvaa. Huikea tuote! Nyt tekisi mieli koko sarjaa.',
          imageUrl: require('@/assets/images/asiakaskokemuksia/taimi_senkki_209_cm_hiili.jpg'),
          productUrl: '/product/taimi-senkki-209x41x75-cm-hiili',
          productName: 'Taimi senkki 209x41x75 cm'
        }
      ]
    }
  }
}
