
import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import type { IBannerItem } from '~/types/contentful/banner'
export default defineComponent({
  name: 'Banner',
  props: {
    banner: {
      type: Object as () => IBannerItem,
      required: true
    },
    eager: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const { backgroundImage, backgroundImageMobile, link: url, route, headline, cssClasses } = props.banner
    const isLoaded = ref(false)
    const hasError = ref(false)
    const mainImageUrl = computed(() => backgroundImageMobile?.url || backgroundImage?.url || '')
    const desktopImageUrl = computed(() => backgroundImage?.url || '')
    const link = computed((): ({ element: 'a'|'nuxt-link'|'div', attrs: { href?: string, to?: string, target?: string }}) => {
      return route ? ({ element: 'nuxt-link', attrs: { to: route } }) : url ? ({ element: 'a', attrs: { href: url, target: '_blank' } }) : ({ element: 'div', attrs: {} })
    })
    return {
      mainImageUrl,
      desktopImageUrl,
      cssClasses,
      link,
      headline,
      isLoaded,
      hasError
    }
  }
})
