
import Vue from 'vue'
import { ArrowRightIcon } from '~/components/veke-ui/icons'
import HeadingLink from '~/components/HeadingLink.vue'
import CampaignTileBanners from '~/components/campaign/CampaignTileBanners.vue'
import VideobotSlider from '~/components/videobot/VideobotSlider.vue'
import ProductTileCarousel from '~/components/product/tile-carousel/ProductTileCarousel.vue'
import Banner from '~/components/Banner.vue'
import YotpoReviewsFrontpage from '~/components/frontpage/yotpo-reviews/YotpoReviewsFrontpage.vue'
import { CampaignActions, CampaignGetters } from '~/store/campaign'
import { BannerActions, BannerGetters } from '~/store/banner'
import type { ICampaignItem } from '~/types/contentful/campaign'
import BlogHighlights from '~/components/blog/BlogHighlights.vue'
import PopularCategories from '~/components/frontpage/PopularCategories.vue'
import VekeXDesigner from '~/components/content/VekeXDesigner.vue'
import AboutUsHomepage from '~/components/content/AboutUsHomepage.vue'

export default Vue.extend({
  name: 'Homepage',
  components: {
    VekeXDesigner,
    AboutUsHomepage,
    Banner,
    HeadingLink,
    CampaignTileBanners,
    ProductTileCarousel,
    YotpoReviewsFrontpage,
    BlogHighlights,
    PopularCategories,
    ArrowRightIcon,
    VideobotSlider
  },
  async asyncData ({ $contentDb, store }) {
    await store.dispatch(CampaignActions.fetchActiveUmbrellaCampaign)
    await store.dispatch(BannerActions.fetchBannersBySlugs, ['etusivu-hero'])
    const vekeXContent = await $contentDb.getContentById('veke-x-homepage')
    const aboutUsContent = await $contentDb.getContentById('about-us-homepage')
    const homepageHero = store.getters[BannerGetters.getBannerBySlug]('etusivu-hero')
    return { homepageHero, vekeXContent, aboutUsContent }
  },
  data () {
    return {
      homepageHero: null
    }
  },
  head () {
    const websiteStructuredData = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: 'https://www.veke.fi/',
      potentialAction: {
        '@type': 'SearchAction',
        target: {
          '@type': 'EntryPoint',
          urlTemplate: 'https://www.veke.fi/haku?q={search_term_string}'
        },
        'query-input': 'required name=search_term_string'
      }
    }
    return {
      title: 'Sisustajan huonekaluliike - tervetuloa ostoksille!',
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: 'Sisustajan huonekaluliike - tervetuloa ostoksille!'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Veke on sisustajan huonekaluliike, jonka laajasta 7000 tuotteen valikoimasta löydät huonekalut ja sisustustuotteet erilaisiin tyyleihin ja makuihin. Tutustu ja inspiroidu!'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'Sisustajan huonekaluliike - tervetuloa ostoksille! - veke.fi'
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: this.$config.BASE_URL
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: 'Veke on sisustajan huonekaluliike, jonka laajasta 7000 tuotteen valikoimasta löydät huonekalut ja sisustustuotteet erilaisiin tyyleihin ja makuihin. Tutustu ja inspiroidu!'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${this.$config.BASE_URL}`
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: websiteStructuredData
        }
      ]
    }
  },
  computed: {
    campaign (): ICampaignItem|null {
      return this.$store.getters[CampaignGetters.getUmbrellaCampaign]
    }
  },
  fetchOnServer: false
})
