
import { defineComponent, useStore, computed } from '@nuxtjs/composition-api'

import Banner from '~/components/Banner.vue'
import { CampaignGetters } from '~/store/campaign'
import type { ICampaignItem } from '~/types/contentful/campaign'
export default defineComponent({
  name: 'CampaignTileBanners',
  components: { Banner },
  props: {
    limit: {
      type: Number,
      required: false,
      default: 8
    },
    showUmbrella: {
      type: Boolean,
      required: false,
      default: false
    },
    campaign: {
      type: Object as () => ICampaignItem|null,
      required: false,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    const campaign = computed(() => props.campaign || store.getters[CampaignGetters.getUmbrellaCampaign])
    const subCampaignTiles = computed(() => campaign.value?.subCampaignsCollection?.items.slice().filter(Boolean).map((t: ICampaignItem) => t.tileBanner).filter(Boolean) || [])

    const tiles = computed(() => {
      if (!props.showUmbrella) {
        return subCampaignTiles.value.slice(0, props.limit)
      } else {
        const umbrellaCampaign = store.getters[CampaignGetters.getUmbrellaCampaign]
        const returnTiles = subCampaignTiles.value.slice(0, props.limit)
        if (umbrellaCampaign?.tileBanner) {
          returnTiles[0] = umbrellaCampaign.tileBanner
        }
        return returnTiles
      }
    })

    return { tiles }
  }
})
