import { render, staticRenderFns } from "./HeadingLink.vue?vue&type=template&id=7be8028d"
import script from "./HeadingLink.vue?vue&type=script&lang=js"
export * from "./HeadingLink.vue?vue&type=script&lang=js"
import style0 from "./HeadingLink.vue?vue&type=style&index=0&id=7be8028d&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/src/nuxt-app/components/BaseButton.vue').default})
