
import {
  defineComponent, nextTick, ref, watch
} from '@nuxtjs/composition-api'
import { XIcon } from '~/components/veke-ui/icons'

export default defineComponent({
  name: 'BaseModal',
  components: {
    XIcon
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },
    contentClass: {
      type: [String, Array],
      required: false,
      default: ''
    },
    overlayClass: {
      type: [String, Array],
      required: false,
      default: ''
    }
  },
  emits: ['close'],
  setup (props, { emit }) {
    const close = () => { emit('close') }
    const modal = ref<any>(null)
    watch(() => props.open, async (open) => {
      if (open && modal.value) {
        await nextTick
        setTimeout(() => {
          if (modal.value) {
            modal.value.focus()
          }
        }, 500)
      }
    })
    return {
      close,
      modal
    }
  }
})
