
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ImageLabelNavigation',
  props: {
    items: {
      type: Array as () => { label: string, path: string, image: string, onSale?: boolean }[],
      required: true
    },
    listClass: {
      type: String,
      required: false,
      default: ''
    }
  }
})
