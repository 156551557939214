
import { defineComponent } from '@nuxtjs/composition-api'
import { PageMessageItem } from '~/types/contentful/page-message'
import PageMessage from '~/components/page-message/PageMessage.vue'
import { PageMessageActions, PageMessageGetters } from '~/store/pageMessage'

export default defineComponent({
  name: 'PageMessages',
  components: { PageMessage },
  props: {
    location: {
      type: String,
      required: true
    }
  },
  async fetch () {
    await this.$store.dispatch(PageMessageActions.fetchPageMessages)
  },
  computed: {
    messages (): PageMessageItem[] {
      return this.$store.getters[PageMessageGetters.getPageMessagesByLocation](this.location)
    }
  }
})
