
import Vue from 'vue'
import BaseButton from '~/components/BaseButton.vue'
import { ArrowRightIcon } from '~/components/veke-ui/icons'
export default Vue.extend({
  name: 'HeadingLink',
  components: {
    ArrowRightIcon,
    BaseButton
  },
  inheritAttrs: false,
  props: {
    buttonText: {
      type: String,
      required: false,
      default: 'Näytä lisää'
    }
  },
  computed: {
    linkElement () {
      return this.$attrs.href ? 'a' : this.$attrs.to ? 'nuxt-link' : 'div'
    }
  }
})
