import { render, staticRenderFns } from "./VekeXDesigner.vue?vue&type=template&id=494bc724"
import script from "./VekeXDesigner.vue?vue&type=script&lang=ts"
export * from "./VekeXDesigner.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VekeLogo: require('/src/nuxt-app/components/VekeLogo.vue').default})
