
import Vue from 'vue'
import VekeCarouselArrows from '~/components/veke-carousel/VekeCarouselArrows.vue'

export default Vue.extend({
  name: 'VekeCarousel',
  components: { VekeCarouselArrows },
  props: {
    overlayArrows: {
      type: Boolean,
      default: false
    },
    scrollPerPage: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 2
    },
    perPageCustom: {
      type: Array,
      default: () => []
    },
    navigationEnabled: {
      type: Boolean,
      default: true
    },
    navigateTo: {
      type: [Number, Array],
      default: 0
    },
    centerMode: {
      type: Boolean,
      default: false
    },
    navigationClasses: {
      type: [String, Array],
      default: ''
    }
  },
  methods: {
    handleNavigation (direction: string) {
      if (this.$refs.vekecarousel) {
        // @ts-ignore
        this.$refs.vekecarousel.handleNavigation(direction)
      }
    }
  }
})
