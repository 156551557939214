
import { defineComponent } from '@nuxtjs/composition-api'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
// @ts-ignore
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import { IContentItem } from '~/types/contentful/content'
import useRichText from '~/mixins/useRichText'
import JointBlockB from '~/components/content/JointBlockB.vue'
export default defineComponent({
  name: 'AboutUsHomepage',
  components: { JointBlockB, RichTextRenderer },
  mixins: [useRichText],
  props: {
    content: {
      type: Object as () => IContentItem,
      required: true
    }
  },
  computed: {
    cssVariables (): any {
      return `--veke-joint-top-bg: ${this.content.alphaColor || '#692314'};--veke-joint-top-fg: ${this.content.betaColor || '#FFFFFF'};--veke-joint-bottom-bg: ${this.content.gammaColor || '#692314'};--veke-joint-bottom-fg: ${this.content.deltaColor || '#FFFFFF'};`
    },
    nodeRenderers (): any {
      return Object.assign({}, this.defaultNodeRenderers, {
        [INLINES.HYPERLINK]: (node: any, key: string, h: any, next: any) => h('nuxt-link', { key, attrs: { class: 'btn btn-secondary mt-2 text-16', to: node.data.uri } }, next(node.content, key, next)),
        [BLOCKS.HEADING_1]: (node: any, key: string, h: any, next: any) => h('h2', { key, attrs: { class: 'alpha' } }, next(node.content, key, next)),
        [BLOCKS.HEADING_2]: (node: any, key: string, h: any, next: any) => h('h2', { key, attrs: { class: 'beta' } }, next(node.content, key, next)),
        [BLOCKS.HEADING_3]: (node: any, key: string, h: any, next: any) => h('h2', { key, attrs: { class: 'gamma' } }, next(node.content, key, next))
      })
    }
  }
})
